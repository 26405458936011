//FETCH_LIST Actions
export const FETCH_TICKET_SUCCESS = "FETCH_FARMERMARKET_SUCCESS";
export const FETCH_TICKET_ERROR = "FETCH_FARMERMARKET_ERROR";

//PAGE_CHANGE Actions
export const TICKET_PAGE_CHANGE = "MARKET_PAGE_CHANGE";

//LIMIT_CHANGE Actions
export const TICKET_LIMIT_CHANGE = "MARKET_LIMIT_CHANGE";

//FETCH_MARKET Actions
export const FETCH_TICKET_INFO_SUCCESS = "FETCH_MARKET_INFO_SUCCESS";
export const FETCH_TICKET_INFO_ERROR = "FETCH_MARKET_INFO_ERROR";

//EDIT_MARKET Actions
export const EDIT_TICKET_SUCCESS = "EDIT_MARKET_SUCCESS";
export const EDIT_TICKET_ERROR = "EDIT_MARKET_ERROR";
//add MARKET ACTIONS
export const ADD_MARKET_SUCCESS = "ADD_MARKET_SUCCESS";
export const ADD_MARKET_ERROR = "ADD_MARKET_ERROR";
//get state
export const GET_STATELIST_SUCCESS = "GET_STATELIST_SUCCESS";
export const GET_STATELIST_ERROR = "GET_STATELIST_ERROR";
//GET county
export const GET_COUNTYLIST_SUCCESS = "GET_COUNTYLIST_SUCCESS";
export const GET_COUNTYLIST_ERROR = "GET_COUNTYLIST_ERROR";
