import * as ACTION_TYPES from '../AccountType/Types'

const initialState = {
    message: "",
    error: null,
    accountTypeList: { recordsTotal: 0 },
    accountType: {},
    subtypeList: { recordsTotal: 0 },
    subType: {},
    page: 1,
    limit: 10,
}

// export const AccountTypeReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case ACTION_TYPES.FETCH_ACCOUNT_TYPE_SUCCESS:
//             return {
//                 ...state,
//                 accountTypeList: action.payload.data,
//                 message: action.payload.message,
//                 error: null
//             }
//         case ACTION_TYPES.ACCOUNT_TYPE_PAGE_CHANGE:
//             return {
//                 ...state,
//                 page: action.payload
//             }
//         case ACTION_TYPES.ACCOUNT_TYPE_LIMIT_CHANGE:
//             return {
//                 ...state,
//                 limit: action.payload
//             }
//         case ACTION_TYPES.DEL_ACCOUNT_TYPE_SUCCESS:
//             return {
//                 ...state,
//                 // category: action.payload.data,
//                 message: action.payload.message,
//                 isEdited: false,
//                 error: null
//             }
//         case ACTION_TYPES.ADD_ACCOUNT_TYPE_SUCCESS:
//         case ACTION_TYPES.FETCH_ACCOUNT_TYPE_INFO_SUCCESS:
//             return {
//                 ...state,
//                 accountType: action.payload.data,
//                 message: action.payload.message,
//                 isEdited: false,
//                 error: null
//             }
//         case ACTION_TYPES.ACCOUNT_TYPE_ACTIVE_STATUS_SUCCESS:
//             return {
//                 ...state,
//                 accountType: action.payload.data,
//                 message: action.payload.message,
//                 isEdited: true,
//                 error: null
//             }
//         case ACTION_TYPES.EDIT_ACCOUNT_TYPE_SUCCESS:
//             return {
//                 ...state,
//                 accountType: action.payload.data,
//                 message: action.payload.message,
//                 isEdited: true,
//                 error: null
//             }
//         case ACTION_TYPES.FETCH_ACCOUNT_TYPE_ERROR:
//         case ACTION_TYPES.DEL_ACCOUNT_TYPE_ERROR:
//         case ACTION_TYPES.ADD_ACCOUNT_TYPE_ERROR:
//         case ACTION_TYPES.ACCOUNT_TYPE_ACTIVE_STATUS_ERROR:
//             return {
//                 ...state,
//                 message: action.payload.data.message,
//                 error: action.payload.data.message,
//                 isEdited: false
//             }
//         case ACTION_TYPES.FETCH_ACCOUNT_TYPE_INFO_ERROR:
//             return {
//                 ...state,
//                 accountType: null,
//                 message: action.payload.data.message,
//                 error: action.payload.data.message
//             }
//         case ACTION_TYPES.EDIT_ACCOUNT_TYPE_ERROR:
//             return {
//                 ...state,
//                 message: action.payload.data.message,
//                 error: action.payload.data.message,
//                 isEdited: false
//             }
//         default:
//             return state
//     }
// }

export const AccountTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ACCOUNT_TYPE_SUCCESS:
            return {
                ...state,
                accountTypeList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.ACCOUNT_TYPE_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.ACCOUNT_TYPE_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.FETCH_ACCOUNT_TYPE_INFO_SUCCESS:
            return {
                ...state,
                accountType: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
            case ACTION_TYPES.FETCH_ACCOUNT_TYPE_INFO_ERROR:
                return {
                    ...state,
                    accountType: null,
                    message: action.payload.data.message,
                    error: action.payload.data.message
                }
        case ACTION_TYPES.EDIT_ACCOUNT_TYPE_SUCCESS:
            return {
                ...state,
                accountType: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_ACCOUNT_TYPE_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.EDIT_ACCOUNT_TYPE_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
       
        default:
            return state
    }
}

