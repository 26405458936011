import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    productList: { recordsTotal: 0 },
    product: {},
    allCategory: {},
    page: 1,
    limit: 10,
}

export const UnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_PRODUCTLIST_SUCCESS:
            return {
                ...state,
                productList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.PRODUCT_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.PRODUCT_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.ADD_PRODUCT_SUCCESS:
        case ACTION_TYPES.FETCH_PRODUCT_INFO_SUCCESS:
            return {
                ...state,
                product: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.EDIT_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_PRODUCTLIST_ERROR:
        case ACTION_TYPES.ADD_PRODUCT_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.FETCH_PRODUCT_INFO_ERROR:
            return {
                ...state,
                product: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.EDIT_PRODUCT_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.GETALL_CATEGORY_SUCCESS:
            return {
                ...state,
                allCategory: action.payload.data,
                message: action.payload.message,
            }
        case ACTION_TYPES.GETALL_CATEGORY_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
            }
        default:
            return state
    }
}

