//FETCH_LIST Actions
export const FETCH_DELIVERY_LIST_SUCCESS = "FETCH_DELIVERY_LIST_SUCCESS";
export const FETCH_DELIVERY_LIST_ERROR = "FETCH_DELIVERY_LIST_ERROR";

//USER_ACTIVE_STATUS Actions
export const DELIVERY_ACTIVE_STATUS_SUCCESS = "DELIVERY_ACTIVE_STATUS_SUCCESS";
export const DELIVERY_ACTIVE_STATUS_ERROR = "DELIVERY_ACTIVE_STATUS_ERROR";

//EDIT_USER Actions
export const EDIT_DELIVERY_SUCCESS = "EDIT_DELIVERY_SUCCESS";
export const EDIT_DELIVERY_ERROR = "EDIT_DELIVERY_ERROR";
