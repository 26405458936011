import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    causeList: {},
    cause: {},
    donationList: {},
    // donationYtdList: {},
    dpage: 1,
    dlimit: 10,
    page: 1,
    limit: 10,
}

export const CauseReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.CAUSE_LIST_SUCCESS:
            return {
                ...state,
                causeList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.CAUSE_ADD_SUCCESS:
            return {
                ...state,
                cause: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.CAUSE_STATUS_SUCCESS:
            return {
                ...state,
                cause: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.CAUSE_DETAIL_SUCCESS:
            return {
                ...state,
                cause: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.CAUSE_EDIT_SUCCESS:
            return {
                ...state,
                cause: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.CAUSE_DONATIONLIST_SUCCESS:
            return {
                ...state,
                donationList: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        // case ACTION_TYPES.CAUSE_YTDDONATIONLIST_SUCCESS:
        //     return {
        //         ...state,
        //         donationYtdList: action.payload.data,
        //         message: action.payload.message,
        //         isEdited: true,
        //         error: null
        //     }
        case ACTION_TYPES.CAUSE_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload,
            };
        case ACTION_TYPES.CAUSE_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload,
            };
        case ACTION_TYPES.DONATION_PAGE_CHANGE:
            return {
                ...state,
                dpage: action.payload,
            };
        case ACTION_TYPES.DONATION_LIMIT_CHANGE:
            return {
                ...state,
                dlimit: action.payload,
            };

        default:
            return state
    }
}

