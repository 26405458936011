import * as ACTION_TYPES from '../NonFoodRate/Types'

const initialState = {
    message: "",
    error: null,
    nonFoodRateList: { recordsTotal: 0 },
    nonFoodRate: {},
    page: 1,
    limit: 10,
}

export const NonFoodRateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_NONFOODRATES_SUCCESS:
            return {
                ...state,
                nonFoodRateList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.NONFOODRATES_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.NONFOODRATES_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.DEL_NONFOODRATES_SUCCESS:
            return {
                ...state,
                // category: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.ADD_NONFOODRATES_SUCCESS:
        case ACTION_TYPES.FETCH_NONFOODRATES_INFO_SUCCESS:
            return {
                ...state,
                nonFoodRate: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.NONFOODRATES_ACTIVE_STATUS_SUCCESS:
            return {
                ...state,
                nonFoodRate: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.EDIT_NONFOODRATES_SUCCESS:
            return {
                ...state,
                nonFoodRate: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_NONFOODRATES_ERROR:
        case ACTION_TYPES.DEL_NONFOODRATES_ERROR:
        case ACTION_TYPES.ADD_NONFOODRATES_ERROR:
        case ACTION_TYPES.NONFOODRATES_ACTIVE_STATUS_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.FETCH_NONFOODRATES_INFO_ERROR:
            return {
                ...state,
                nonFoodRate: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.EDIT_NONFOODRATES_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        default:
            return state
    }
}
