import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    categoryList: { recordsTotal: 0 },
    category: {},
    page: 1,
    limit: 10,
}

export const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_CATEGORYLIST_SUCCESS:
            return {
                ...state,
                categoryList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.CATEGORY_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.CATEGORY_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.DEL_CATEGORY_SUCCESS:
            return {
                ...state,
                // category: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.ADD_CATEGORY_SUCCESS:
        case ACTION_TYPES.FETCH_CATEGORY_INFO_SUCCESS:
            return {
                ...state,
                category: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.CATEGORY_ACTIVE_STATUS_SUCCESS:
            return {
                ...state,
                category: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.EDIT_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_CATEGORYLIST_ERROR:
        case ACTION_TYPES.DEL_CATEGORY_ERROR:
        case ACTION_TYPES.ADD_CATEGORY_ERROR:
        case ACTION_TYPES.CATEGORY_ACTIVE_STATUS_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.FETCH_CATEGORY_INFO_ERROR:
            return {
                ...state,
                category: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.EDIT_CATEGORY_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        default:
            return state
    }
}
