//FETCH Version Details
export const GET_GROWER_VERSION_SUCCESS = "GET_GROWER_VERSION_SUCCESS";
export const GET_GROWER_VERSION_ERROR = "GET_GROWER_VERSION_ERROR";

//FETCH Version Details
export const GET_FETCHER_VERSION_SUCCESS = "GET_FETCHER_VERSION_SUCCESS";
export const GET_FETCHER_VERSION_ERROR = "GET_FETCHER_VERSION_ERROR";

//UPDATE Version Details
export const UPDATE_FETCHER_VERSION_SUCCESS = "UPDATE_FETCHER_VERSION_SUCCESS";
export const UPADTE_FETCHER_VERSION_ERROR = "UPDATE_FETCHER_VERSION_ERROR";

//UPDATE Version Details
export const UPDATE_GROWER_VERSION_SUCCESS = "UPDATE_GROWER_VERSION_SUCCESS";
export const UPADTE_GROWER_VERSION_ERROR = "UPDATE_GROWER_VERSION_ERROR";