//FETCH_LIST Actions
export const CAUSE_LIST_SUCCESS = "CAUSE_LIST_SUCCESS";
export const CAUSE_LIST_ERROR = "CAUSE_LIST_ERROR";

//Add Cause
export const CAUSE_ADD_SUCCESS = "CAUSE_ADD_SUCCESS";
export const CAUSE_ADD_ERROR = "CAUSE_ADD_ERROR";

//Cause status
export const CAUSE_STATUS_SUCCESS = "CAUSE_STATUS_SUCCESS";
export const CAUSE_STATUS_ERROR = "CAUSE_STATUS_ERROR";

//PAGE_CHANGE Actions
export const CAUSE_PAGE_CHANGE = "CAUSE_PAGE_CHANGE";

//LIMIT_CHANGE Actions
export const CAUSE_LIMIT_CHANGE = "CAUSE_LIMIT_CHANGE";

//PAGE_CHANGE Actions
export const DONATION_PAGE_CHANGE = "DONATION_PAGE_CHANGE";

//LIMIT_CHANGE Actions
export const DONATION_LIMIT_CHANGE = "DONATION_LIMIT_CHANGE";


//Cause update
export const CAUSE_EDIT_SUCCESS = "CAUSE_EDIT_SUCCESS";
export const CAUSE_EDIT_ERROR = "CAUSE_EDIT_ERROR";

//Cause detail
export const CAUSE_DETAIL_SUCCESS = "CAUSE_DETAIL_SUCCESS";
export const CAUSE_DETAIL_ERROR = "CAUSE_DETAIL_ERROR";

//cause donation list
export const CAUSE_DONATIONLIST_SUCCESS = "CAUSE_DONATIONLIST_SUCCESS";
export const CAUSE_DONATIONLIST_ERROR = "CAUSE_DONATIONLIST_ERROR";

// //cause ytd donation list
// export const CAUSE_YTDDONATIONLIST_SUCCESS = "CAUSE_YTDDONATIONLIST_SUCCESS";
// export const CAUSE_YTDDONATIONLIST_ERROR = "CAUSE_YTDDONATIONLIST_ERROR";