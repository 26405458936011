//get all customers
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";

//get all farmers
export const GET_FARMER_SUCCESS = "GET_FARMER_SUCCESS";
export const GET_FARMER_ERROR = "GET_FARMER_ERROR";

//send push notification
export const SEND_PUSHNOTIFICATION_SUCCESS = "SEND_PUSHNOTIFICATION_SUCCESS";
export const SEND_PUSHNOTIFICATION_ERROR = "SEND_PUSHNOTIFICATION_ERROR";