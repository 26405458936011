//FETCH_LIST Actions
export const FETCH_NONFOODRATES_SUCCESS = "FETCHNONFOODRATES_SUCCESS";
export const FETCH_NONFOODRATES_ERROR = "FETCHNONFOODRATES_ERROR";
//PAGE_CHANGE Actions
export const NONFOODRATES_PAGE_CHANGE = "NONFOODRATES_PAGE_CHANGE"
//LIMIT_CHANGE Actions
export const NONFOODRATES_LIMIT_CHANGE = "NONFOODRATES_LIMIT_CHANGE"
//DEL_NONFOODRATES Actions
export const DEL_NONFOODRATES_SUCCESS = "DEL_NONFOODRATES_SUCCESS";
export const DEL_NONFOODRATES_ERROR = "DEL_NONFOODRATES_ERROR";
//NONFOODRATES_ACTIVE_STATUS Actions
export const NONFOODRATES_ACTIVE_STATUS_SUCCESS = "NONFOODRATES_ACTIVE_STATUS_SUCCESS";
export const NONFOODRATES_ACTIVE_STATUS_ERROR = "NONFOODRATES_ACTIVE_STATUS_ERROR";
//FETCH_NONFOODRATES Actions
export const FETCH_NONFOODRATES_INFO_SUCCESS = "FETCH_NONFOODRATES_INFO_SUCCESS";
export const FETCH_NONFOODRATES_INFO_ERROR = "FETCH_NONFOODRATES_INFO_ERROR";
//EDIT_NONFOODRATES Actions
export const EDIT_NONFOODRATES_SUCCESS = "EDIT_NONFOODRATES_SUCCESS";
export const EDIT_NONFOODRATES_ERROR = "EDIT_NONFOODRATES_ERROR";
//add NONFOODRATES ACTIONS
export const ADD_NONFOODRATES_SUCCESS = "ADD_NONFOODRATES_SUCCESS";
export const ADD_NONFOODRATES_ERROR = "ADD_NONFOODRATES_ERROR";