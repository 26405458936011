import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'


// To login
export const login = user => dispatch => {
    return axios
        .post(`${API_BASE}auth/adminLogin`, { ...user })
        .then((res) => {
            if (res.data.status === 200) {
                // set access token for rest of the api
                axios.defaults.headers.common['Authorization'] = res.data.data.accessToken;
                dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err })
            throw err
        })
}

//To logout 
export const logout = (id) => dispatch => {
    return axios
        .post(`${API_BASE}auth/logout`, id)
        .then(res => {
            dispatch({ type: ACTION_TYPES.LOGOUT })
            return res.data
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.LOGOUT })
            throw err
        })
}

//To Send OTP (Forgot Password)
export const forgotPass = (data) => (dispatch) => {
    return axios
        .post(`${API_BASE}auth/forgotPassword`, data)
        .then((res) => {
            dispatch({
                type: ACTION_TYPES.FORGOT_PASSWORD_SUCCESS,
                payload: res.data,
            });
            return res.data;
        })
        .catch((err) => {
            dispatch({
                type: ACTION_TYPES.FORGOT_PASSWORD_ERROR,
                payload: err.response,
            });
            throw err;
        });
};

// To change password
export const changePass = data => dispatch => {
    return axios
        .post(`${API_BASE}api/adminChangePassword`, data)
        .then(res => {
            dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, payload: res.data })
            return res.data
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}


//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}