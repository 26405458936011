//FETCH_LIST Actions
export const FETCH_PRODUCTLIST_SUCCESS = "FETCH_PRODUCTLIST_SUCCESS";
export const FETCH_PRODUCTLIST_ERROR = "FETCH_PRODUCTLIST_ERROR";
//PAGE_CHANGE Actions
export const PRODUCT_PAGE_CHANGE = "PRODUCT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const PRODUCT_LIMIT_CHANGE = "PRODUCT_LIMIT_CHANGE"
// //DEL_PRODUCT Actions
// export const DEL_PRODUCT_SUCCESS = "DEL_PRODUCT_SUCCESS";
// export const DEL_PRODUCT_ERROR = "DEL_PRODUCT_ERROR";
// //USER_ACTIVE_STATUS Actions
// export const USER_ACTIVE_STATUS_SUCCESS = "USER_ACTIVE_STATUS_SUCCESS";
// export const USER_ACTIVE_STATUS_ERROR = "USER_ACTIVE_STATUS_ERROR";
//FETCH_USER Actions
export const FETCH_PRODUCT_INFO_SUCCESS = "FETCH_PRODUCT_INFO_SUCCESS";
export const FETCH_PRODUCT_INFO_ERROR = "FETCH_PRODUCT_INFO_ERROR";
//EDIT_USER Actions
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";
//add user ACTIONS
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
//product dropdown
export const GETALL_CATEGORY_SUCCESS = "GETALL_CATEGORY_SUCCESS";
export const GETALL_CATEGORY_ERROR = "GETALL_CATEGORY_ERROR";