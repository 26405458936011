import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    notification: {},
    allCustomer: {},
    allFarmer: {}
}

export const PushNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SEND_PUSHNOTIFICATION_SUCCESS:
            return {
                ...state,
                notification: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                allCustomer: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_FARMER_SUCCESS:
            return {
                ...state,
                allFarmer: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CUSTOMER_ERROR:
        case ACTION_TYPES.GET_FARMER_ERROR:
        case ACTION_TYPES.SEND_PUSHNOTIFICATION_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
            }
        default:
            return state
    }
}